<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'MopBreadcrumb',
});

const props = defineProps({
  breadcrumbs: {
    type: Array as PropType<SeoBreadcrumb[]>,
    required: true,
  },
});

const { $mopI18n } = useNuxtApp();
const breadcrumbList: SeoBreadcrumb[] = [
  {
    name: 'Marc O’Polo',
    url: $mopI18n.localePath(''),
  },
  ...props.breadcrumbs,
];
</script>

<template>
  <ul v-if="breadcrumbs" class="breadcrumb">
    <li v-for="(breadcrumb, i) in breadcrumbList" :key="i" class="breadcrumb__item">
      <NuxtLink :to="breadcrumb.url" class="breadcrumb__link" no-prefetch>
        {{ breadcrumb.name }}
      </NuxtLink>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.breadcrumb {
  margin: $space15 0;
}

.breadcrumb__item {
  display: inline;
}

.breadcrumb__item:not(:last-child) {
  &::after {
    padding: 0 1px;
    content: '/';
  }
}

.breadcrumb__link {
  @include link-not-underlined(0);

  padding: 3px 0;
  display: inline;
  letter-spacing: 0.04em;
}
</style>
